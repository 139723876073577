import React, { useState } from "react";

import {createCoupon} from "../helpers/couponHelpers.js"

function getAuthToken() {
    return localStorage.getItem("auth");
  }
const CreateCouponPage = () => {
  const [discountType, setDiscountType] = useState("percentage");
  const [discountValue, setDiscountValue] = useState("");
  const [discountPeriods, setDiscountPeriods] = useState("");
  const [message, setMessage] = useState("");


  const handleSubmit = async (e) => {
    e.preventDefault();
    
    // Call the helper function to create the coupon
    const result = await createCoupon(discountType, discountValue, discountPeriods);
    
    if (result.success) {
      setMessage(`Coupon created successfully: ${result.couponCode}`);
    } else {
      setMessage(result.message);
    }
  };

  return (
    <div>
      <h1>Create Coupon</h1>
      <form onSubmit={handleSubmit}>
        <div>
          <label>Discount Type:</label>
          <select
            value={discountType}
            onChange={(e) => setDiscountType(e.target.value)}
          >
            <option value="percentage">Percentage</option>
            <option value="fixed">Fixed</option>
          </select>
        </div>
        <div>
          <label>Discount Value:</label>
          <input
            type="number"
            value={discountValue}
            onChange={(e) => setDiscountValue(e.target.value)}
            required
          />
        </div>
        <div>
          <label>Discount Periods (in weeks):</label>
          <input
            type="number"
            value={discountPeriods}
            onChange={(e) => setDiscountPeriods(e.target.value)}
            required
          />
        </div>
        <button type="submit">Create Coupon</button>
      </form>
      {message && <p>{message}</p>}
    </div>
  );
};

export default CreateCouponPage;

import React from 'react';
import './Coupon.css'; // Import a CSS file for styling

const Coupon = ({ code, value, period, type, expiration }) => {
    // Format the value based on type
    const formattedValue = type == 'fixed' ? `Diskon Rp${value}` : value != 0 ? `Diskon${value}%` : 'Voucher berlangganan';

    // Function to convert expiration to Indonesian date format (dd MMMM yyyy)
    const formatExpirationDate = (dateString) => {
        const date = new Date(dateString);

        // Options for Indonesian date format
        const options = {
            weekday: 'long', // 'Monday'
            year: 'numeric', // '2025'
            month: 'long', // 'Januari'
            day: 'numeric' // '11'
        };

        // Format the date to Indonesian locale (ID)
        return date.toLocaleDateString('id-ID', options);
    };

    // Function to calculate the difference in days between expiration and current date (adjusted to UTC)
    const calculateDaysLeft = (expirationDate) => {
        const currentDate = new Date();
        const expiration = new Date(expirationDate);

        // Convert both dates to UTC
        const utcCurrentDate = new Date(currentDate.toISOString()); // Ensure it's in UTC
        const utcExpirationDate = new Date(expiration.toISOString()); // Ensure it's in UTC

        // Calculate the time difference in milliseconds
        const timeDifference = utcExpirationDate - utcCurrentDate;
        const daysLeft = Math.ceil(timeDifference / (1000 * 3600 * 24)); // Convert to days

        return daysLeft;
    };

    const daysLeft = expiration ? calculateDaysLeft(expiration) : null;

    return (
        <div className='coupon'>
            {(expiration == null && code != null) || (expiration != null && code != null && daysLeft < 1) && (
                <div className='RibbonBannerInverted'>
                    <img src={"https://i.imgur.com/yt6osgL.png"}></img>
                    <h1>Voucher berakhir</h1>
                </div>
            )}
            <div className='coupon-left'>
                <div className='coupon-code'>{code == null ? '404' : code}</div>
                {/* <div className='dotted-line'></div> */}
            </div>
            <div className='coupon-right'>
                <h2 className='coupon-value'>{code == null ? 'Voucher tidak ada' : formattedValue}</h2>
                {type && <span className='coupon-type'>{type}</span>} {/* Display type if provided */}
                <p className='coupon-period'>
                    {code == null ? '-' : value === 0 ? `Masa berlangganan ${period} minggu` : `Masa Voucher ${period} minggu`}
                </p>
                <p className='coupon-expiration'>
                    {expiration == null
                        ? (code == null ? '-' : 'Tanpa kadaluarsa')
                        : daysLeft < 0
                            ? `Berakhir ${Math.abs(daysLeft)} hari lalu`
                            : daysLeft <= 7
                                ? `Berlaku hingga ${daysLeft} hari lagi`
                                : `Berlaku hingga: ${formatExpirationDate(expiration)}`}
                </p>

            </div>
        </div>
    );
};

export default Coupon;

import React, { useState, useEffect } from "react";
import Chart from "react-apexcharts";
import styles from "./BarChart.module.css"; // Import the CSS module

const DailyCharts = ({ incomeGraph, transactionGraph, materialGraph, colors, type, graphFilter }) => {
  const [selectedIndex, setSelectedIndex] = useState(-1);

  useEffect(() => {
    setSelectedIndex(-1);
  }, [transactionGraph]);

  const processData = (graphData) => {
    if (!graphData) return null;
    return graphData.map((dayData) => {
      const categories = [
        "0-3",
        "3-6",
        "6-9",
        "9-12",
        "12-15",
        "15-18",
        "18-21",
        "21-24",
      ];
      console.log(dayData)
      let seriesData = []
      if(graphFilter == 'transactions'){
      seriesData = [
        dayData.hour0To3Transactions.reduce((acc, t) => acc + t.sold, 0),
        dayData.hour3To6Transactions.reduce((acc, t) => acc + t.sold, 0),
        dayData.hour6To9Transactions.reduce((acc, t) => acc + t.sold, 0),
        dayData.hour9To12Transactions.reduce((acc, t) => acc + t.sold, 0),
        dayData.hour12To15Transactions.reduce((acc, t) => acc + t.sold, 0),
        dayData.hour15To18Transactions.reduce((acc, t) => acc + t.sold, 0),
        dayData.hour18To21Transactions.reduce((acc, t) => acc + t.sold, 0),
        dayData.hour21To24Transactions.reduce((acc, t) => acc + t.sold, 0),
      ];
      }
      else if(graphFilter == 'income'){
        seriesData = [
          dayData.hour0To3Transactions.reduce((acc, t) => acc + t.totalPrice, 0),
          dayData.hour3To6Transactions.reduce((acc, t) => acc + t.totalPrice, 0),
          dayData.hour6To9Transactions.reduce((acc, t) => acc + t.totalPrice, 0),
          dayData.hour9To12Transactions.reduce((acc, t) => acc + t.totalPrice, 0),
          dayData.hour12To15Transactions.reduce((acc, t) => acc + t.totalPrice, 0),
          dayData.hour15To18Transactions.reduce((acc, t) => acc + t.totalPrice, 0),
          dayData.hour18To21Transactions.reduce((acc, t) => acc + t.totalPrice, 0),
          dayData.hour21To24Transactions.reduce((acc, t) => acc + t.totalPrice, 0),
        ];
      }
      else if(graphFilter == 'outcome'){
        seriesData = [
          dayData.hour3To6MaterialIds.reduce((acc, t) => acc + t.materialOutcome, 0),
          dayData.hour6To9MaterialIds.reduce((acc, t) => acc + t.materialOutcome, 0),
          dayData.hour0To3MaterialIds.reduce((acc, t) => acc + t.materialOutcome, 0),
          dayData.hour9To12MaterialIds.reduce((acc, t) => acc + t.materialOutcome, 0),
          dayData.hour12To15MaterialIds.reduce((acc, t) => acc + t.materialOutcome, 0),
          dayData.hour15To18MaterialIds.reduce((acc, t) => acc + t.materialOutcome, 0),
          dayData.hour18To21MaterialIds.reduce((acc, t) => acc + t.materialOutcome, 0),
          dayData.hour21To24MaterialIds.reduce((acc, t) => acc + t.materialOutcome, 0),
        ];
      }
      return {
        date: new Date(dayData.date).toLocaleDateString(),
        categories,
        series: [
          {
            name: `Transactions on ${new Date(dayData.date).toLocaleDateString()}`,
            data: seriesData,
          },
        ],
      };
    });
  };

  const chartData = processData(graphFilter != 'outcome' ? transactionGraph : materialGraph);

  let globalMax = null;
  if (chartData)
    globalMax = chartData.reduce(
      (max, data) => {
        const localMax = Math.max(...data.series[0].data);
        return localMax > max ? localMax : max;
      },
      0
    );

    const formatDate = (dateString) => {
      const date = new Date(dateString);  // Parse the date string
      
      // Create an array of month names (use the same names you had earlier)
      const monthNames = [
        "Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"
      ];
      
      // Get the month and day
      const month = monthNames[date.getMonth()];  // Month is 0-indexed (January = 0)
      const day = date.getDate();  // Get the day of the month
      
      return { month, day };  // Return the result
    };
    
  return (
    <div className={`${styles.chartItemContainer} ${selectedIndex !== -1 ? styles.expanded : ''}`}>
      {chartData &&
        chartData.map((data, index) => (
          <div
            key={index}
            className={`${styles.chartItemWrapper} ${selectedIndex !== -1 && selectedIndex !== index
              ? styles.chartItemWrapperActive
              : styles.chartItemWrapperInactive
              }`}
          >
            <div className={styles.dateSelectorWrapper}>
              {chartData.map((item, indexx) => {
                const { month, day } = formatDate(item.date);
                return (
                  <div
                    key={indexx}
                    className={`${styles.dateSelector} ${index === indexx ? styles.dateSelectorActive : styles.dateSelectorInactive
                      }`}
                      style={{position: 'relative' }}
                    onClick={() =>
                      type == 'yesterday' && selectedIndex == -1 || type != 'yesterday' && selectedIndex !== index ? setSelectedIndex(index) : setSelectedIndex(-1)
                    }
                    // style={{ backgroundColor: index === indexx ? colors[index % colors.length] : 'transparent' }}
                  >
                    <div style={{position: 'absolute', bottom: 0, left: '10%', right: '10%', borderBottom: index == indexx ? `2px solid ${colors[index % colors.length]}` : 'none'}}></div>
                    <div 
                    style={{ color: index === indexx ? 'black' : 'transparent' }}>
                      {indexx !== chartData.length - 1 ? (
                        <>
                          {day}{" "}
                          {(indexx === 0 || (formatDate(chartData[indexx - 1].date).month !== month && type != 'weekly')) && month}
                        </>
                      ) : (
                        'Kemarin'
                      )}

                    </div>
                  </div>
                );
              })}
            </div>
            <div className={styles.chartWrapper}>
              <Chart
                options={{
                  tooltip: { enabled: false },
                  chart: {
                    id: `chart-${index}`,
                    type: "area",
                    zoom: {
                      enabled: false,
                    },
                    toolbar: {
                      show: false,
                    },
                  },
                  xaxis: {
                    categories: data.categories,
                    labels: {
                      style: {
                        colors: index === 0 || index == selectedIndex || selectedIndex == 0 && index == 1 ? "#000" : "transparent",
                      },
                    },
                  },
                  yaxis: {
                    max: globalMax,
                    min: 0,
                    labels: {
                      maxWidth: 20,
                      style: {
                        colors: "transparent",
                      },
                    },
                  },
                  grid: {
                    show: false,
                  },
                  fill: {
                    opacity: 0.5,
                  },
                  colors: [colors[index % colors.length]],
                }}
                series={data.series}
                type="area"
                height={200}
                width="100%"
              />
            </div>
          </div>

        ))}
    </div>
  );
};

export default DailyCharts;

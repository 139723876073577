// LinktreePage.js
import React, { useState, useEffect } from 'react';
import styles from './Join.module.css'; // Import the module.css file
import { checkCoupon, logCouponForUser } from '../helpers/couponHelpers';  // Import the new helper
import Coupon from '../components/Coupon';

import {
  getLocalStorage,
} from "../helpers/localStorageHelpers";

const LinktreePage = ({ data, setModal }) => {
  const queryParams = new URLSearchParams(window.location.search);
  const [isOnlyClaimCoupon, setIsOnlyClaimCoupon] = useState(false);
  const [isUsingCoupon, setIsUsingCoupon] = useState(false);
  const [couponCode, setCouponCode] = useState('');
  const [couponStatus, setCouponStatus] = useState(0);
  const [couponDetails, setCouponDetails] = useState(null);

  // Detect query params on component mount
  useEffect(() => {
    if (couponCode !== '') return;
    const modal = queryParams.get('modal');
    const code = queryParams.get('couponCode');

    console.log(code)
    if (modal == 'claim-coupon') {
      if (!getLocalStorage('auth')) {

        // Preserve the couponCode query param while navigating to the claim-coupon modal
        const newUrl = `?modal=join${code ? `&couponCode=${code}` : ''}`;
        window.location.href = newUrl; // This will update the URL and reload the page
      }

      setIsOnlyClaimCoupon(true)
      setIsUsingCoupon(true);  // Automatically switch to the coupon input state
    }
    else {
      if (getLocalStorage('auth')) {

        // Preserve the couponCode query param while navigating to the claim-coupon modal
        const newUrl = `?modal=claim-coupon${code ? `&couponCode=${code}` : ''}`;
        window.location.href = newUrl; // This will update the URL and reload the page
      }
    }
    if (code) {
      setCouponCode(code);
      setIsUsingCoupon(true);  // Automatically switch to the coupon input state
      handleCheckCoupon(code); // Automatically check the coupon code
    }
  }, [queryParams]);

  // Handle manual coupon code check
  const handleCheckCoupon = async (code = couponCode) => {
    const result = await checkCoupon(code);  // Call the helper
    setCouponStatus(result.coupon ? 200 : 404);
    setCouponDetails(result.coupon);
  };

  // Handle manual coupon code check
  const handleLogCouponForUser = async (code = couponCode) => {
    const result = await logCouponForUser(code);  // Call the helper
  };

  // Listen for query parameter changes (using the `location` object)
  useEffect(() => {
    const handlePopState = () => {
      const newQueryParams = new URLSearchParams(window.location.search);
      const newCouponCode = newQueryParams.get('couponCode');
      if (!newCouponCode) {
        setIsUsingCoupon(false);
        setCouponCode('');
        setCouponDetails(null);
        setCouponStatus(0);
      }
    };
    window.addEventListener('popstate', handlePopState);
    return () => {
      window.removeEventListener('popstate', handlePopState);
    };
  }, []);

  return (
    <div className={styles.linktreePage}>
      {!isUsingCoupon ? (
        <div className={styles.dashboardContainer}>
          <div className={styles.mainHeading}>Nikmati Kemudahan Mengelola Kafe</div>
          <div className={styles.subHeadingTransparent}>
            Daftarkan kedaimu sekarang dan mulai gunakan semua fitur unggulan kami.
          </div>
          <form className={styles.linktreeForm}>
            <label htmlFor="username" className={styles.usernameLabel}>
              --------------------------------------------
            </label>
            <input
              id="username"
              placeholder="nomor whatsapp atau email"
              maxLength="30"
              className={styles.usernameInput}
            />
            <button type="submit" className={styles.claimButton}>
              <span>➜</span>
            </button>
          </form>
          <div className={styles.footer}>
            <div className={styles.footerLinks}>
              <a
                href="https://linktr.ee/discover/trending"
                target="_blank"
                rel="noreferrer"
                className={styles.footerLink}
              >
                Pelajari lebih lanjut
              </a>
              <a
                onClick={() => setIsUsingCoupon(true)}
                className={styles.footerLink}
              >
                Gunakan Voucher
              </a>
            </div>
          </div>
        </div>
      ) : (
        <div className={styles.dashboardContainer}>
          <div className={styles.mainHeading}>{isOnlyClaimCoupon ? 'Aktifkan Voucher' : 'Daftar Dengan Voucher'}</div>
          <div className={styles.subHeading}>
            Voucher dapat digunakan untuk pembuatan akun bisnis maupun untuk memperpanjang masa berlangganan.
          </div>
          {couponStatus === 0 ? (
            <form className={styles.linktreeForm} onSubmit={(e) => e.preventDefault()}>
              <label htmlFor="coupon" className={styles.usernameLabel}>
                --------------------------------------------
              </label>
              <input
                id="coupon"
                placeholder="Kode Voucher"
                maxLength="30"
                className={styles.usernameInput}
                value={couponCode}
                onChange={(e) => setCouponCode(e.target.value)}
              />
              <button onClick={() => handleCheckCoupon()} className={styles.claimButton}>
                <span>Cek</span>
              </button>
            </form>
          ) : (
            <>
              <Coupon
                code={couponDetails?.code || null}
                value={couponDetails?.discountValue}
                period={couponDetails?.discountPeriods}
                type={couponDetails?.type}
                expiration={couponDetails?.expirationDate}
              />
              {couponStatus === 200 &&
                <div className={styles.linktreeForm}>
                  <label htmlFor="username" className={styles.usernameLabel}>
                    --------------------------------------------
                  </label>
                  <button
                    className={styles.claimButton}
                    style={{ width: '266px' }}
                    onClick={() => {
                      if (!isOnlyClaimCoupon) {
                        // If it's only claiming a coupon, trigger claim logic
                        setModal('create_user', { codeStatus: 200, couponCode });
                      } else {
                        // Otherwise, handle the coupon for user creation
                        handleLogCouponForUser();
                      }
                    }}
                  >
                    <span>{isOnlyClaimCoupon ? 'Aktifkan untuk akun ini' : 'Buat akun bisnis dengan voucher ini'}</span>
                  </button>

                </div>
              }
            </>
          )}
          <div className={styles.footer}>
            <div className={styles.footerLinks}>
              {!getLocalStorage('auth') &&
              <a
                href="https://linktr.ee/discover/trending"
                target="_blank"
                rel="noreferrer"
                className={styles.footerLink}
              >
                Sudah punya akun bisnis?
              </a>
}
              <a
                href="https://linktr.ee/discover/trending"
                target="_blank"
                rel="noreferrer"
                className={styles.footerLink}
              >
                Pelajari lebih lanjut
              </a>
              {(!isOnlyClaimCoupon || couponStatus != 0) &&
                <a
                  onClick={() => {
                    const url = new URL(window.location.href);
                    url.searchParams.delete('couponCode');
                    url.searchParams.delete('codeStatus');
                    window.history.pushState({}, '', url.toString());
                    setIsUsingCoupon(couponStatus === 0 ? false : true);
                    setCouponCode('');
                    setCouponDetails(null);
                    setCouponStatus(0);
                  }}
                  className={styles.footerLink}
                >
                  Kembali
                </a>
              }
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default LinktreePage;

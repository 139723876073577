import React, { useState, useEffect } from "react";
import Chart from "react-apexcharts";
import styles from "./BarChart.module.css"; // Import the CSS module

const PeriodCharts = ({ type, graphFilter, aggregatedCurrentReports, aggregatedPreviousReports, colors }) => {
  const [selectedIndex, setSelectedIndex] = useState(-1);

  useEffect(() => {
    setSelectedIndex(-1);
  }, [aggregatedCurrentReports, aggregatedPreviousReports]);

  const monthly = ["1 - 7", "8 - 14", "15 - 21", "22 - 28", "29 - 31"];
  const yearly = ["Kuartal 1", "Kuartal 2", "Kuartal 3", "Kuartal 4"];
  const cat = type == 'monthly' ? monthly : yearly;

  // Map the data for the current reports
  let currentIncomeData, currentOutcomeData, currentTransactionData, previousIncomeData, previousOutcomeData, previousTransactionData = null;
  if (aggregatedCurrentReports) {
    currentIncomeData = aggregatedCurrentReports.map((report) => report.income);
    currentOutcomeData = aggregatedCurrentReports.map((report) => report.outcome);
    currentTransactionData = aggregatedCurrentReports.map((report) => report.transactions);

    if (type == 'monthly' && currentIncomeData.length === 4) {
      currentIncomeData.push(null);
    }
    if (type == 'monthly' && currentOutcomeData.length === 4) {
      currentOutcomeData.push(null);
    }
    if (type == 'monthly' && currentTransactionData.length === 4) {
      currentTransactionData.push(null);
    }
  }
  if (aggregatedPreviousReports) {
    // Map the data for the previous reports
    previousIncomeData = aggregatedPreviousReports.map((report) => report.income);
    previousOutcomeData = aggregatedPreviousReports.map((report) => report.outcome);
    previousTransactionData = aggregatedPreviousReports.map((report) => report.transactions);

    if (type == 'monthly' && previousIncomeData.length === 4) {
      previousIncomeData.push(null);
    }
    if (type == 'monthly' && previousOutcomeData.length === 4) {
      previousOutcomeData.push(null);
    }
    if (type == 'monthly' && previousTransactionData.length === 4) {
      previousTransactionData.push(null);
    }
  }
let globalMax = null;
if (aggregatedCurrentReports || aggregatedPreviousReports) {
  // Find the global maximum for the y-axis
  globalMax = Math.max(
    ...(graphFilter === 'income' 
      ? [...currentIncomeData, ...previousIncomeData]
      : graphFilter === 'outcome' 
      ? [...currentOutcomeData, ...previousOutcomeData]
      : [...currentTransactionData, ...previousTransactionData])
  );
}

  return (
    <div className={`${styles.chartItemContainer} ${selectedIndex !== -1 ? styles.expanded : ''}`}>
      {aggregatedPreviousReports && (
        <div className={`${styles.chartItemWrapper} ${selectedIndex !== -1 && selectedIndex !== 0
          ? styles.chartItemWrapperActive
          : styles.chartItemWrapperInactive
          }`}>

          <div className={styles.dateSelectorWrapper}>
            <div className={styles.dateSelector}
              onClick={() =>
                selectedIndex === -1 ? setSelectedIndex(0) : setSelectedIndex(-1)
              }
              style={{ color: 'black', position: 'relative' }}
            >
              <div style={{ position: 'absolute', bottom: 0, left: '10%', right: '10%', borderBottom: `2px solid ${colors[0]}` }}></div>
              <div>{type == 'monthly' ? 'bulan lalu' : 'tahun lalu'}</div>
            </div>
            <div
              className={`${styles.dateSelector} ${styles.dateSelectorInactive
                }`}

              onClick={() =>
                selectedIndex === 0 ? setSelectedIndex(-1) : setSelectedIndex(1)
              }>
              <div>{type == 'monthly' ? 'bulan ini' : 'tahun ini'}</div>
            </div>
          </div>
          <div className={styles.chartWrapper}>
            <Chart
              options={{
                tooltip: { enabled: false },
                chart: { type: "area", zoom: { enabled: false }, toolbar: { show: false } },
                xaxis: {
                  categories: cat,
                  axisBorder: {
                    show: false, // Removes the x-axis line
                  },
                  axisTicks: {
                    show: false, // Removes the ticks on the x-axis
                  },
                  labels: {
                    style: {
                  colors: ['black', 'black', 'black', 'black', aggregatedPreviousReports?.length == 4 ? 'transparent' : 'black'],
                    }
                  }
                },
                yaxis: { max: globalMax, min: 0, labels: { 
                  maxWidth: 20, style: { colors: "transparent" } } },
                grid: { show: false },
                fill: { opacity: 0.5 },
                colors: [colors[0]],
              }}
              series={[
                // { name: "Pemasukan", data: previousIncomeData },
                // { name: "Pengaluaran", data: previousOutcomeData },
                { name: "Total transaksi", data: graphFilter == 'income' ? previousIncomeData : graphFilter == 'outcome' ? previousOutcomeData : previousTransactionData },
              ]}
              type="area"
              height={200}
              width="100%"
            />
          </div>
        </div>
      )}
      {aggregatedCurrentReports && (
        <div className={`${styles.chartItemWrapper} ${selectedIndex !== -1 && selectedIndex !== 1
          ? styles.chartItemWrapperActive
          : styles.chartItemWrapperInactive
          }`}>
          <div className={styles.dateSelectorWrapper}>
            <div
              className={`${styles.dateSelector} ${styles.dateSelectorInactive
                }`}
              onClick={() =>
                selectedIndex === 1 ? setSelectedIndex(-1) : setSelectedIndex(0)
              }>
              <div>{type == 'monthly' ? 'bulan lalu' : 'tahun lalu'}</div>
            </div>
            <div className={styles.dateSelector}
              onClick={() =>
                selectedIndex === -1 ? setSelectedIndex(1) : setSelectedIndex(-1)
              }

              style={{ color: 'black', position: 'relative' }}
            >
              <div style={{ position: 'absolute', bottom: 0, left: '10%', right: '10%', borderBottom: `2px solid ${colors[1]}` }}></div>
              <div>{type == 'monthly' ? 'bulan ini' : 'tahun ini'}</div>
            </div>
          </div>
          <div className={styles.chartWrapper}>
            <Chart
              options={{
                tooltip: { enabled: false },
                chart: { type: "area", zoom: { enabled: false }, toolbar: { show: false } },
                xaxis: {
                  categories: cat,
                  axisBorder: {
                    show: false, // Removes the x-axis line
                  },
                  axisTicks: {
                    show: false, // Removes the ticks on the x-axis
                  },
                  labels: {
                    style: {
                      colors: ['black', 'black', 'black', 'black', aggregatedCurrentReports?.length == 4 ? 'transparent' : 'black'],
                    }
                  }
                },
                yaxis: {  max: globalMax, min: 0, labels: {  maxWidth: 20, style: { colors: "transparent" } } },
                grid: { show: false },
                fill: { opacity: 0.5 },
                colors: [colors[1]],
              }}
              series={[
                // { name: "Pemasukan", data: currentIncomeData },
                // { name: "Pengeluaran", data: currentOutcomeData },
                { name: "Total transaksi", data: graphFilter == 'income' ? currentIncomeData : graphFilter == 'outcome' ? currentOutcomeData : currentTransactionData },
              ]}
              type="area"
              height={200}
              width="100%"
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default PeriodCharts;

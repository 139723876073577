// LinktreePage.js
import React, { useState, useEffect } from 'react';
import styles from './Join.module.css'; // Import the module.css file
import { ThreeDots } from "react-loader-spinner";

const LinktreePage = () => {

  return (
    <div className={styles.linktreePage}>
            <div style={{
              width: '150px',
              height: '150px',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}>
              <ThreeDots color="#FFFFFF" height={40} width={40} />
            </div>
    </div>
  );
};

export default LinktreePage;
